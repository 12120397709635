import {Injectable} from "@angular/core";
import {ApiService} from "@app/core-module/services/api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {OrgType} from "@shared/models/org-type";
import {Observable} from "rxjs";
import {of} from "rxjs";

@Injectable()
export class OrgTypesState {

  private orgTypes: Observable<OrgType[]>;

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService) {

    this.orgTypes = this.rxUtilsService.createGetStateValue(of(true), this.apiService.getOrgTypes(),
      'Error while reading Organisation Types', []);
  }

  public getOrgTypes() {
    return this.orgTypes;
  }
}
