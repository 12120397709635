import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from '@app/components/login/login.component';
import {ActivateAccountComponent} from '@app/components/activate-account/activate-account.component';
import {ForgotPasswordComponent} from '@app/components/forgot-password/forgot-password.component';
import {NewPasswordComponent} from '@app/components/new-password/new-password.component';
import {TfaSetupComponent} from '@app/components/tfa-setup/tfa-setup.component';

const routes: Routes = [
  {path: '', redirectTo: `/auth/login`, pathMatch: 'full'},
  {path: 'auth', redirectTo: `/auth`, pathMatch: 'full'},
  {
    path: 'auth', children: [
      {path: 'login', component: LoginComponent},
      // {path: 'sign-up', component: SignUpComponent},
      {path: 'activate', component: ActivateAccountComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'forgot-password/:token', component: NewPasswordComponent},
      {path: 'set-password/:token', component: NewPasswordComponent},
      {path: 'tfa-setup', component: TfaSetupComponent}
    ]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'fi',
    loadChildren: () => import('./fi-module/fi.module').then(m => m.FiModule)
  },
  {path: '**', redirectTo: `/auth`},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
