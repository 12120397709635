import {Injectable} from "@angular/core";
import {ApiService} from "@app/core-module/services/api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {AccType} from "@shared/models/acc-type";
import {Observable} from "rxjs";
import {of} from "rxjs";

@Injectable()
export class AccTypesState {

  private accTypes: Observable<AccType[]>;

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService) {

    this.accTypes = this.rxUtilsService.createGetStateValue(of(true), this.apiService.getAccTypes(),
      'Error while reading Account Types', []);
  }

  public getAccTypes() {
    return this.accTypes;
  }
}
