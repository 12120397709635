import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {defaultDebounceTime, getLogoPath, MatchPasswordValidator} from '@app/shared-module/utils/utils';
import {Observable} from 'rxjs';
import {debounceTime, filter, finalize, first} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {LanguageService} from '@app/core-module/services/language.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  animations: [routerTransition()]
})
export class NewPasswordComponent implements OnInit {

  private currentProfile: Observable<Profile>;
  passwordChanged = false;

  token = null;
  formGroup: FormGroup;

  working$ = new BehaviorSubject<boolean>(false);
  working = this.working$.asObservable().pipe(debounceTime(defaultDebounceTime));
  logoPath: string;
  isForgotPasswordPage: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private currentProfileState: CurrentProfileState,
              private authenticationService: AuthenticationService,
              private alertsService: AlertsService,
              private languageService: LanguageService) {
    this.isForgotPasswordPage = this.router.url.includes('/forgot-password');
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.currentProfile.pipe(
      filter(it => it !== null),
      first()
    ).subscribe(profile => {
      if (profile.id) {
        if (profile.user != null && profile.user.isAdminFI) {
          this.router.navigate(['/admin']);
        } else if (profile.user) {
          this.router.navigate(['/fi']);
        } else {
          this.router.navigate(['/profile']);
        }
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.token != null) {
        this.token = params.token;
      } else {
        this.router.navigate(['/auth/forgot-password']);
      }
    });
    this.formGroup = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6), MatchPasswordValidator]),
      verify: new FormControl('', [Validators.required, MatchPasswordValidator]),
    });
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  changePassword() {
    this.working$.next(true);
    const form = this.formGroup.value;
    form['token'] = this.token;
    this.authenticationService.changePassword(form)
      .pipe(
        finalize(() => this.working$.next(false))
      ).subscribe(res => this.passwordChanged = true, err => {
      this.alertsService.addAlert({type: 'danger', message: err.error.message});
    });
  }

}
