import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {Observable} from 'rxjs';
import {WireTransferFormStatusTypeConverted} from '@shared/models/wire-transfer-form-status-type';
import {filter, map} from 'rxjs/operators';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';

@Injectable()
export class WireTransferFormStatusTypesState {

  private wireTransferFormStatusTypes: Observable<{ outgoingStatuses: WireTransferFormStatusTypeConverted[], incomingStatuses: WireTransferFormStatusTypeConverted[] }>;

  constructor(private currentBankState: CurrentBankState,
              private apiService: ApiService,
              private rxUtilsService: RxUtilsService) {

    const initiator = this.currentBankState.getCurrentBankId().pipe(
      filter(it => it != null)
    );

    this.wireTransferFormStatusTypes = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getWireTransferFormStatuses(),
      'Error while reading Wire Transfer Form Statuses', {outgoingStatuses: [], incomingStatuses: []});
  }

  getOutgoingStatuses(): Observable<WireTransferFormStatusTypeConverted[]> {
    return this.wireTransferFormStatusTypes.pipe(
      filter(it => !!it),
      map(it => it.outgoingStatuses)
    );
  }

  getIncomingStatuses(): Observable<WireTransferFormStatusTypeConverted[]> {
    return this.wireTransferFormStatusTypes.pipe(
      filter(it => !!it),
      map(it => it.incomingStatuses)
    );
  }
}
