import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {ApiService} from '@app/core-module/services/api.service';
import {Country_ISO} from '@shared/models/country_iso';

@Injectable()
export class CountriesService {

  private countries: Observable<Country_ISO[]>;

  constructor(private apiService: ApiService) {
    this.countries = this.apiService.getCountries().pipe(shareReplay(1));
  }

  getCountries() {
    return this.countries;
  }
}
