import {OfacCheckForName} from "./ofac-check-for-name";
import {OfacCheckForAddress} from "./ofac-check-for-address";
import {OfacAddress} from "./ofac-address";
import {DbModel} from "./db.model";

export class Ofac extends DbModel {
    ofac_id: string;
    entity_number: string;
    name: string;
    source: string;
    source_information_url: string;
    source_list_url: string;
    title: string;
    type: string;
    score: number;
    alt_names: string[];
    addresses: OfacAddress[];
    dates_of_birth: string[];
    places_of_birth: string[];
    programs: string[];
    ofac_check_for_name_id: number;
    ofac_check_for_address_id: number;

    //generated
    recordInformationForAddress: { address: string, country: string };
    ofac_check_for_name: OfacCheckForName;
    ofac_check_for_address: OfacCheckForAddress;
    recordInformation: string;
}

export class OfacAltName extends DbModel {
    ofac_id: number;
    alt_name: string;

    //generated
    ofac: Ofac;
}

export class OfacDateOfBirth extends DbModel {
    ofac_id: number;
    date_of_birth: string;

    //generated
    ofac: Ofac;
}

export class OfacPlaceOfBirth extends DbModel {
    ofac_id: number;
    place_of_birth: string;

    //generated
    ofac: Ofac;
}

export class OfacProgram extends DbModel {
    ofac_id: number;
    program: string;

    //generated
    ofac: Ofac;
}

export class OfacRDCResult {
    byPayeeName: Ofac[];
    byPayorName: Ofac[];
    byAddress: Ofac[];

    public getAllResults(): Ofac[] {
        return [...this.byPayeeName, ...this.byPayorName, ...this.byAddress];
    }

    public getNameResults(): Ofac[] {
        return [...this.byPayeeName, ...this.byPayorName];
    }

    public getAddressResults(): Ofac[] {
        return [...this.byAddress];
    }
}


export class OfacProfileResult {
    ofacForPersonName: { check: OfacCheckForName, records: Ofac[] };
    ofacForWireTransferBankName: { check: OfacCheckForName, records: Ofac[] };
    ofacForPersonAddress: { check: OfacCheckForAddress, records: Ofac[] };
    ofacForWireTransferBankAddress: { check: OfacCheckForAddress, records: Ofac[] };
    answerContactRecords: { check: OfacCheckForName, records: Ofac[] }[];
    answerAddressRecords: { check: OfacCheckForAddress, records: Ofac[] }[];
}
