import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {AccountsState} from '@app/fi-module/states/accounts.state';
import {Balance} from '@shared/models/balance';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, merge} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable()
export class BalancesState {

  private readonly balancesNoKycProfileId: Observable<Balance[]>;
  private readonly balancesWithKycProfileId: Observable<Balance[]>;
  private readonly allBalances: Observable<Balance[]>;

  private readingNoKycProfileId: Observable<boolean>;
  private readingWithKycProfileId: Observable<boolean>;
  private readingAll: Observable<boolean>;

  private readBalances$ = new Subject();


  constructor(private apiService: ApiService,
              private accountsState: AccountsState,
              private rxUtilsService: RxUtilsService) {


    const initiator = this.accountsState.getAccounts().pipe(merge(this.readBalances$));
    this.balancesNoKycProfileId = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getBalances('no'),
      'Error while reading balance!', []);
    this.balancesWithKycProfileId = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getBalances('yes'),
      'Error while reading balance!', []);
    this.allBalances = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getBalances('all'),
      'Error while reading balance!', []);

    this.readingNoKycProfileId = this.rxUtilsService.createReadingInfo(initiator, this.balancesNoKycProfileId);
    this.readingWithKycProfileId = this.rxUtilsService.createReadingInfo(initiator, this.balancesWithKycProfileId);
    this.readingAll = this.rxUtilsService.createReadingInfo(initiator, this.allBalances);
  }

  getBalances(): Observable<Balance[]> {
    return this.allBalances;
  }

  getBalancesNoKycProfileId(): Observable<Balance[]> {
    return this.balancesNoKycProfileId;
  }

  getBalancesWithKycProfileId(): Observable<Balance[]> {
    return this.balancesWithKycProfileId;
  }

  getReadingAllBalances(): Observable<boolean> {
    return this.readingAll.pipe(distinctUntilChanged());
  }

  getReadingBalancesNoKycProfileId(): Observable<boolean> {
    return this.readingNoKycProfileId.pipe(distinctUntilChanged());
  }

  getReadingBalancesWithKycProfileId(): Observable<boolean> {
    return this.readingWithKycProfileId.pipe(distinctUntilChanged());
  }

  reload() {
    this.readBalances$.next(true);
  }
}
