import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import {TokenState} from '@app/core-module/states/token.state';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {filter, first, switchMap, timeout} from 'rxjs/operators';
import {CustomerDocument} from '@shared/models/customer-document';

export abstract class AbstractApiService {

  protected domain = environment.domain;

  constructor(protected http: HttpClient) {
  }

  private getAccessToken(): string | null {
    return localStorage.session ? JSON.parse(localStorage.session).accessToken : null;
  }

  protected getOptions(reportProgress = false) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getAccessToken(),
      'TimezoneOffset': new Date().getTimezoneOffset() + ''
    });
    return {headers: headers, reportProgress: reportProgress};
  }

  protected getBlobOptions() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getAccessToken(),
      'TimezoneOffset': new Date().getTimezoneOffset() + ''
    });
    return {headers: headers, responseType: 'blob' as 'blob', observe: 'response' as 'response'};
  }

  protected createGetRequest<T>(uri: string): Observable<T> {
    return this.http.get<T>(this.domain + uri, this.getOptions());
  }

  protected createBlobGetRequest(uri: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.domain + uri, this.getBlobOptions()).pipe(timeout(1000 * 60 * 20));
  }

  protected createBlobPostRequest(uri: string, object: any): Observable<HttpResponse<Blob>> {
    return this.http.post(this.domain + uri, object, this.getBlobOptions()).pipe(timeout(1000 * 60 * 20));
  }

  protected createPostRequest<T>(uri: string, object = null): Observable<T> {
    return this.http.post<T>(this.domain + uri, object, this.getOptions());
  }

  protected createPostRequestWithProgress<T>(uri: string, object = null): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', this.domain + uri, object, this.getOptions(true));
    return this.http.request(req);
  }

  protected createPutRequest<T>(uri: string, object: any): Observable<T> {
    return this.http.put<T>(this.domain + uri, object, this.getOptions());
  }

  protected createDeleteRequest<T>(uri: string): Observable<T> {
    return this.http.delete<T>(this.domain + uri, this.getOptions());
  }

  private prepareParams(params: {}) {
    const keys = Object.keys(params);
    keys.forEach(key => {
      if (params[key] == null) {
        delete params[key];
      }
    });
  }

  protected createGetRequestWithParams<T>(uri: string, params: {}): Observable<T> {
    this.prepareParams(params);
    const options = this.getOptions();
    options['params'] = params;
    return this.http.get<T>(this.domain + uri, options);
  }

  protected createBlobGetRequestWithParams<T>(uri: string, params: {}): Observable<HttpResponse<Blob>> {
    this.prepareParams(params);
    const options = this.getBlobOptions();
    options['params'] = params;
    return this.http.get(this.domain + uri, options);
  }

  public abstract postCustomerDocuments(profileId: number, formData: FormData): Observable<CustomerDocument[]>;

  public abstract deleteCustomerDocument(profileId: number, documentId: number): Observable<CustomerDocument>;

}
