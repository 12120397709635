import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {filter, merge} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {StatusCode} from "@shared/models/status-code";

@Injectable()
export class RdcStatusCodesState {

  private rdcStatusCodesObservable: Observable<StatusCode[]>;
  private readingObservable: Observable<boolean>;
  private readFilters = new Subject();

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService,
              private currentProfileState: CurrentProfileState) {

    const initiator = this.currentProfileState.getCurrentProfileId().pipe(
      filter(it => it != null),
      merge(this.readFilters)
    );
    this.rdcStatusCodesObservable = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getRdcStatusCodes(),
      'Error while getting RDC status codes', null);
    this.readingObservable = this.rxUtilsService.createReadingInfo(initiator, this.rdcStatusCodesObservable);

  }

  getRdcStatusCodes() {
    return this.rdcStatusCodesObservable;
  }

  getReading() {
    return this.readingObservable;
  }

}
