import {Injectable} from "@angular/core";
import {AlertsService} from "@app/core-module/services/alerts.service";
import {catchError, map, merge, publishReplay, refCount, shareReplay, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {Observable} from "rxjs";
import {notEmpty} from "@shared/utils/utils";

@Injectable()
export class RxUtilsService {

  constructor(private alertsService: AlertsService) {

  }

  private createSafeRequest<T>(request: Observable<T>, errorMessage: string, valueOnError: T) {
    return request.pipe(
      catchError(error => {
        if ( error.status !== 401 && notEmpty(errorMessage)) {
          this.alertsService.addAlert({type: 'danger', message: errorMessage});
        }
        return of(valueOnError);
      }),
    );
  }

  /**
   * The observable will call the requestFunction only once, even if it has several subscriptions.
   * It will keep the last value even when all subscriptions are unsubscribed
   * (which allows caching for any new subscriptions)
   */
  public createGetStateValue<T, P>(initiator: Observable<P>, getRequest: Observable<T>, errorMessage: string, valueOnError: T): Observable<T> {
    return initiator.pipe(
      switchMap(() => this.createSafeRequest(getRequest, errorMessage, valueOnError)),
      shareReplay(1)
    );
  }

  /**
   * The observable will call the requestFunction only once, even if it has several subscriptions.
   * It will keep the last value even when all subscriptions are unsubscribed
   * (which allows caching for any new subscriptions)
   */
  public createGetValue<T, P>(initiator: Observable<P>, getRequestFunction: (value: P) =>
    Observable<T>, errorMessage: string, valueOnError: T): Observable<T> {
    return initiator.pipe(
      switchMap(initiatorValue => this.createSafeRequest(getRequestFunction(initiatorValue), errorMessage, valueOnError)),
      shareReplay(1)
    );
  }

  /**
   * The observable will call the requestFunction only once, even if it has several subscriptions.
   * It will unsubscribe once all subscriptions are unsubscribed.
   */
  public createSharedValueNotCached<T>(initiator: Observable<any>, getRequestFunction: (value) =>
    Observable<T>, errorMessage: string, valueOnError: T): Observable<T> {
    return initiator.pipe(
      switchMap(initiatorValue => this.createSafeRequest(getRequestFunction(initiatorValue), errorMessage, valueOnError)),
      publishReplay(1),
      refCount()
    );
  }

  public createReadingInfo<T>(initiator: Observable<any>, valueToBeRead: Observable<T>): Observable<boolean> {
    return initiator.pipe(
      map(it => true),
      merge(valueToBeRead.pipe(map(() => false))),
      shareReplay(1),
    );
  }

  /**
   * The observable will call the requestFunction only once, even if it has several subscriptions.
   * It will unsubscribe once all subscriptions are unsubscribed.
   */
  public createReadingInfoSharedNotCached<T>(initiator: Observable<any>, valueToBeRead: Observable<T>): Observable<boolean> {
    return initiator.pipe(
      map(it => true),
      merge(valueToBeRead.pipe(map(() => false))),
      publishReplay(1),
      refCount()
    );
  }

}
