import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {defaultDebounceTime, getLogoPath} from '@app/shared-module/utils/utils';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {LanguageService} from '@app/core-module/services/language.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  animations: [routerTransition()]
})
export class ActivateAccountComponent implements OnInit {

  currentProfile: Observable<Profile>;
  readingCurrentProfile: Observable<boolean>;
  showActivationField = false;
  showActivating = false;
  showActive = false;
  formGroup: FormGroup;
  errorMessage = '';
  showErrorMessage = false;
  logoPath: string;

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService,
              private currentProfileState: CurrentProfileState,
              private languageService: LanguageService) {
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.readingCurrentProfile = this.currentProfileState.getReadingCurrentProfile().pipe(debounceTime(defaultDebounceTime));
    this.formGroup = new FormGroup({
      code: new FormControl('', [Validators.required])
    });
  }

  setLanguage(languageCode: string) {
    this.languageService.setLanguage(languageCode);
  }

  ngOnInit() {
    const code = this.route.snapshot.queryParams['code'];
    if (code == null) {
      this.showActivationField = true;
      this.formGroup = new FormGroup({
        code: new FormControl('', [Validators.required])
      });
    } else {
      this.showActivating = true;
      this.authService.activate(code).subscribe((res) => {
        this.showActivating = false;
        this.showActive = true;
      }, (error) => {
        this.showActivationField = true;
        this.showActivating = false;
        this.showActive = false;
        this.errorMessage = error.error.message;
        this.showErrorMessage = true;
      });
    }
  }

  activate() {
    this.showActivationField = false;
    this.showActivating = true;
    this.authService.activate(this.formGroup.get('code').value).subscribe((res) => {
      this.showActivating = false;
      this.showActive = true;
    }, (error) => {
      this.showActivationField = true;
      this.showActivating = false;
      this.showActive = false;
      this.errorMessage = error.error.message;
      this.showErrorMessage = true;
    });
  }

  hideErrorMessage() {
    this.showErrorMessage = false;
  }

}
