import {Injectable} from '@angular/core';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Settlement} from '@shared/models/settlement';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, first, merge, switchMap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable()
export class SettlementsState {

  private settlements: Observable<Settlement[]>;
  private publishedSettlements: Observable<Settlement[]>;
  private allSettlements: Observable<Settlement[]>;

  private reading: Observable<boolean>;
  private readingPublished: Observable<boolean>;
  private readingAllSettlements: Observable<boolean>;

  private read$ = new Subject();


  constructor(private adminApiService: AdminApiService,
              private currentProfileState: CurrentProfileState,
              private rxUtilsService: RxUtilsService) {


    let initiator = this.currentProfileState.getCurrentProfileId().pipe(
      switchMap(() => this.currentProfileState.getCurrentProfile().pipe(first())),
      filter(it => it != null && it.user != null && it.user.isAdminFI),
      merge(this.read$.asObservable())
    );

    this.settlements = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getSettlements(),
      'Error while reading settlements!', []);
    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.settlements);

    this.allSettlements = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getAllSettlements(),
      'Error while reading settlements!', []);
    this.readingAllSettlements = this.rxUtilsService.createReadingInfo(initiator, this.allSettlements);

    this.publishedSettlements = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getPublishedSettlements(),
      'Error while reading settlements!', []);
    this.readingPublished = this.rxUtilsService.createReadingInfo(initiator, this.publishedSettlements);
  }

  getSettlements(): Observable<Settlement[]> {
    return this.settlements;
  }

  getPublishedSettlements(): Observable<Settlement[]> {
    return this.publishedSettlements;
  }

  getAllSettlements(): Observable<Settlement[]> {
    return this.allSettlements;
  }

  getReading(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  getReadingPublished(): Observable<boolean> {
    return this.readingPublished;
  }

  getReadingAllSettlements(): Observable<boolean> {
    return this.readingAllSettlements;
  }

  reload() {
    this.read$.next(true);
  }
}
