import {notEmpty} from "../utils/utils";

export class ScannerInfo {
  Vendor: string;
  Product: string;
  Release: string;
  batchAvailable: boolean;
  numBatchAvailable: number;
  printCartridgeLoaded: boolean;

  public isConnected(): boolean {
    return notEmpty(this.Vendor) && notEmpty(this.Product) && notEmpty(this.Release);
  }
}
