import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';
import {Account} from '@shared/models/account';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, merge} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable()
export class AccountsState {

  private accounts: Observable<Account[]>;
  private reading: Observable<boolean>;

  private readAccounts$ = new Subject();


  constructor(private apiService: ApiService,
              private currentBankState: CurrentBankState,
              private rxUtilsService: RxUtilsService) {

    const initiator = this.currentBankState.getCurrentBankId().pipe(
      filter(it => it != null),
      merge(this.readAccounts$)
    );
    this.accounts = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getAccounts(),
      'Error while reading accounts!', []);
    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.accounts);
  }

  getAccounts(): Observable<Account[]> {
    return this.accounts;
  }

  /**
   * Returns Observable emitting only true of false, never null or an error.
   */
  getReadingAccounts(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }
}
