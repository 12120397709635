<div class="alert-box">
  <ngb-alert *ngFor="let alert of alerts" (close)="removeAlert(alert)" type="{{alert.type}}">{{ alert.message }}</ngb-alert>
</div>
<div class="debug-box">
  <ngb-alert *ngFor="let alert of debugAlerts" (close)="removeDebugAlert(alert)" type="{{alert.type}}">{{ alert.message }}</ngb-alert>
</div>
<router-outlet></router-outlet>




