import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'shared-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {

  @Input()
  value = 100;

  constructor() {
  }

  ngOnInit() {
  }

}
