import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {MAX_ROWS_IN_TABLE} from '@shared/utils/constants';

const numberOfShownItems = MAX_ROWS_IN_TABLE;

@Injectable()
export class TableFooterService {

  lessThanThousand = {
    selectedMessage: this.translateService.instant('shared.table-footer: selected'),
    emptyMessage: '',
    totalMessage: this.translateService.instant('shared.table-footer: total')
  };

  constructor(private translateService: TranslateService) {}

  createMessageWithCount(numberOfRecords: any) {
    return {
      selectedMessage: this.translateService.instant('shared.table-footer: selected'),
      emptyMessage: '',
      totalMessage: this.translateService.instant('shared.table-footer: displayed out of {{numberOfRecords}} records found',
        {numberOfRecords: numberOfRecords.toLocaleString('en')})
    };
  }

  createCalculatingMessageWithCount() {
    return {
      selectedMessage: this.translateService.instant('shared.table-footer: selected'),
      emptyMessage: '',
      totalMessage: this.translateService.instant('shared.table-footer: calculating')
    };
  }

  getMessage(numberOfRecords: number, isCalculating: boolean) {
    if (isCalculating) {
      return this.createCalculatingMessageWithCount();
    } else {
      return numberOfRecords < numberOfShownItems ? this.lessThanThousand : this.createMessageWithCount(numberOfRecords);
    }
  }

}
