import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NavbarService implements OnInit {

  private navbarOpen$: BehaviorSubject<'opened' | 'closed'> = new BehaviorSubject(
    (localStorage.getItem('menu') as 'opened' | 'closed') || 'opened');

  public navbarToggle = this.navbarOpen$.asObservable();

  toggleFunction = (status: 'opened' | 'closed') => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
    this.navbarOpen$.next(status);
    localStorage.setItem('menu', status);
  }

  constructor() {
  }

  ngOnInit() {
  }


}
