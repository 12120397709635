import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ToggleGraphsService {

  toggleGraphs$ = new Subject<boolean>();
  constructor() {
  }
}
