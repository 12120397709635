import {WiresBatches} from "../models/wires_batches";
import {RDC} from "../models/rdc";
import {Person} from "../models/person";
import {Account} from '../models/account';

type ExportWireBatchTypes = "excel" | "swift" | "fed" | 'mcb';
export type IncludeKycProfile = "yes" | "no" | "all";

export const timezone = "America/Chicago";
export const timeFormat = "YYYYMMDD_HHmm";

export function getPersonName(person: Person): string | undefined {
    if (!person) return undefined;
    return (person.first_name && person.last_name) ? [person.first_name.trim(), person.last_name.trim()].filter(it => it).join(' ') :
        person && person.name ? person.name.trim() : '';
}

export function notEmpty(string: string): boolean {
    return string !== undefined && string !== null && string.length > 0;
}

export function ToArray(enumm: any) {
    return Object.keys(enumm).map(key => enumm[key]);
}

export function isEmpty(string: string): boolean {
    return !notEmpty(string);
}

export function getExportFileNameWireBatch(batch: WiresBatches, type: ExportWireBatchTypes) {
    if (type === "excel") return `wire-forms-ionfi_export-batch-${batch.name_prefix}-${batch.id}.xlsx`;
    if (type === "fed") return `fedwire-ionfi_export-batch-${batch.name_prefix}-${batch.id}.txt`;
    if (type === "swift") return `swift-ionfi_export-batch-${batch.name_prefix}-${batch.id}.txt`;
    if (type === "mcb") return `mcb-ionfi_export-batch-${batch.name_prefix}-${batch.id}.txt`;
}

export function isAllowedToEdit(rdc: RDC) {
    return rdc.statusCode.status === "Not Processed"
        || rdc.statusCode.status === "Pending"
        || rdc.statusCode.status === "Data Review"
        || (rdc.statusCode.status === "Info Required" && (rdc.previousStatusCode.status === "Pending" || rdc.previousStatusCode.status === "Data Review"));
}

export function getParamsFromFragment(fragment: string): { key: string, values: string[] }[] {
    const result = [];
    const elements = fragment.split('&');
    for (const element of elements) {
        const keyValues = element.split('=');
        if (keyValues && keyValues.length === 2) {
            const key = keyValues[0];
            const values = keyValues[1].split(',');
            result.push({
                key: key,
                values: values
            })
        }
    }
    return result;
}

export enum isFilterTypeDate {
    createdFrom, createdTo, registeredFrom, registeredTo, dateFrom, dateTo
}
