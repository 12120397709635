import {Injectable} from '@angular/core';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {UpstreamBank} from '@shared/models/upstream-bank';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BraidProductDb} from "@shared/models/braid-product-db";
import {ApiService} from "@app/core-module/services/api.service";
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';

@Injectable()
export class UpstreamBanksState {


  private readUpstreamBank$ = new BehaviorSubject<boolean>(true);

  private readingUpstreamBanks: Observable<boolean>;
  private upstreamBanks: Observable<UpstreamBank[]>;
  private upstreamBanksWithCutoffTime: Observable<UpstreamBank[]>;

  public constructor(private adminApiService: AdminApiService,
                     private currentProfileState: CurrentProfileState,
                     private rxUtilsService: RxUtilsService,
                     private apiService: ApiService) {

    const startSignal = this.readUpstreamBank$.asObservable();

    this.upstreamBanks = this.rxUtilsService.createGetStateValue(
      startSignal,
      this.adminApiService.getUpstreamBanks(),
      'Error while reading the list of Upstream Banks',
      []);
    this.readingUpstreamBanks = this.rxUtilsService.createReadingInfo(startSignal, this.upstreamBanks);

    this.upstreamBanksWithCutoffTime = this.rxUtilsService.createGetStateValue(
      startSignal,
      this.currentProfileState.getCurrentProfile().pipe(
        switchMap(currentProfile => this.apiService.getUpstreamBanksForUser(currentProfile.user.id))
      ),
      'Error while reading the list of Upstream Banks',
      []
    );
  }

  public addUpstreamBanks(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.adminApiService.addUpstreamBank(upb).pipe(
      tap(() => this.readUpstreamBank$.next(true))
    );
  }

  public update(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.adminApiService.updateUpstreamBank(upb).pipe(
      tap(() => this.readUpstreamBank$.next(true))
    );
  }


  public updateBraid(fi_id: number, usb_id: number, product_name: string, product_id: number): Observable<BraidProductDb> {
    return this.adminApiService.updateBraidProduct(fi_id, usb_id, product_name, product_id).pipe(
      tap(() => this.readUpstreamBank$.next(true))
    );
  }

  public repairBraidWebhook(upbId: number): Observable<UpstreamBank> {
    return this.adminApiService.repairUpstreamBankBraidWebhooks(upbId).pipe(
      tap(() => this.readUpstreamBank$.next(true))
    );
  }

  public delete(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.adminApiService.deleteUpstreamBank(upb).pipe(
      tap(() => this.readUpstreamBank$.next(true))
    );
  }

  public getUpstreamBanksWithCutoffTime() {
    return this.upstreamBanksWithCutoffTime;
  }

  public getUpstreamBanks(): Observable<UpstreamBank[]> {
    return this.upstreamBanks;
  }

  public getReadingUpstreamBanks() {
    return this.readingUpstreamBanks.pipe(distinctUntilChanged());
  }
}
