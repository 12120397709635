import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {
  IErrorResponseError,
  ERROR_BRAID_ENABLED_BUT_WFORM_NOT_SEGREGATED,
  ERROR_BRAID_NOT_ENABLED_FOR_UPB,
  ERROR_BUSINESS_BLOCKED_BY_BRAID,
  ERROR_CANT_CREATE_TRAN_IN_BRAID_WITH_BANKS_WITH_SAME_NUM_TYPE,
  ERROR_COUNTERPARTY_IN_BRAID_IS_NOT_ACTIVE,
  ERROR_CREATING_BUSINESS_IN_BRAID,
  ERROR_CREATING_INDIVIDUAL_IN_BRAID,
  ERROR_CREATING_UPDATING_COUNTERPARTY_IN_BRAID,
  ERROR_DOCS_NOT_ATTACHED_IN_QUESTIONNAIRE,
  ERROR_ID_DOC_NOT_ATTACHED_IN_QUESTIONNAIRE,
  ERROR_INDIVIDUAL_BLOCKED_BY_BRAID,
  ERROR_INDIVIDUAL_NOT_SYNCED_WITH_BRAID,
  ERROR_INVALID_BENEFICIARY_BANK,
  ERROR_INVALID_BENEFICIARY_PERSON,
  ERROR_INVALID_BENEFICIARY_PERSON_DDA_NUMBER,
  ERROR_INVALID_BRAID_PRODUCT,
  ERROR_INVALID_BRAID_PRODUCT_DATA,
  ERROR_INVALID_BRAID_WEBHOOKS_RESPONSE,
  ERROR_INVALID_BUSINESS_IN_BRAID,
  ERROR_INVALID_COUNTERPARTY_IN_BRAID,
  ERROR_INVALID_DOMESTIC_AMOUNT_VALUE,
  ERROR_INVALID_ID_TYPE,
  ERROR_INVALID_INDIVIDUAL_IN_BRAID,
  ERROR_INVALID_INTERMEDIARY_BANK,
  ERROR_INVALID_LOCAL_FUNDING_ACCOUNT,
  ERROR_INVALID_LOCAL_RECEIVER_ACC,
  ERROR_INVALID_LOCAL_SEGREGATED_ACCOUNT,
  ERROR_INVALID_LOCAL_SENDER_ACC,
  ERROR_INVALID_ORGANIZATION_TYPE,
  ERROR_INVALID_ORIGINATOR_PERSON,
  ERROR_INVALID_SEGREGATED_PERSON_PROFILE,
  ERROR_INVALID_TENANT_ID_IN_BRAID,
  ERROR_INVALID_UPB_CONFIG,
  ERROR_INVALID_UPB_FOR_BRAID_API,
  ERROR_INVALID_UPB_FOR_LOCAL_FUNDING_ACCOUNT,
  ERROR_INVALID_UPB_FOR_LOCAL_RECEIVER_ACC,
  ERROR_INVALID_UPB_FOR_LOCAL_SEGREGATED_ACCOUNT,
  ERROR_INVALID_UPB_FOR_LOCAL_SENDER_ACC,
  ERROR_INVALID_USERNAME_OR_API_KEY_IN_BRAID,
  ERROR_INVALID_WFORM,
  ERROR_INVALID_WIRE_LOCAL_ACC,
  ERROR_LEGAL_ENTITY_NOT_SYNCED_WITH_BRAID,
  ERROR_LOCAL_SEGREGATED_ACC_MUST_HAVE_0_CURRENT_BALANCE,
  ERROR_LOCAL_SEGREGATED_ACC_NOT_REGISTERED_IN_BRAID,
  ERROR_LOCAL_SEGREGATED_ACC_NOT_SYNCED_WITH_BRAID,
  ERROR_LOCAL_WIRE_ACC_NOT_REGISTERED_IN_BRAID,
  ERROR_WFORM_INTERNATIONAL_INTER_BANK_FOR_NON_ABA_ROUTING_NUM_BENEFICIARY,
  ERROR_NOT_ALLOWED_LOCAL_ACC_SYNC_WITH_BRAID,
  ERROR_NOT_SAME_UPB_FOR_WIRE_ACC_AND_SEGREGATED_ACC,
  ERROR_PERSON_TYPE_NOT_INDIVIDUAL,
  ERROR_PERSON_TYPE_NOT_LEGAL_ENTITY,
  ERROR_UNKNOWN_BRAID_ACC_NUMBER,
  ERROR_UNKNOWN_KYC_PROFILE,
  ERROR_UNKNOWN_KYC_PROFILE_TO_SYNC_WITH_BRAID,
  ERROR_UNKNOWN_LOCAL_ACC,
  ERROR_UNKNOWN_LOCAL_SEGREGATED_ACC,
  ERROR_UNKNOWN_PERSON,
  ERROR_UNKNOWN_UPB,
  ERROR_UNSUPPORTED_WAY_TO_CREATE_TRAN_IN_BRAID,
  ERROR_UPDATING_BUSINESS_IN_BRAID,
  ERROR_UPDATING_INDIVIDUAL_IN_BRAID,
  ERROR_WHILE_VERIFYING_UPB_CREDENTIALS,
  ERROR_BRAID_INSUFFICIENT_FUNDS_ON_SENDER_ACCOUNT,
  ERROR_FEDWIRE_FILE_5000_OR_5010_REQUIRED,
  ERROR_WFORM_INTERNATIONAL_NO_BANK_WITH_ABA_ROUTING_NUMBER
} from '@shared/utils/response-error';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AlertsService {


  private addAlert$: Subject<Alert> = new Subject<Alert>();
  private alerts: Observable<Alert>;

  constructor(private translateService: TranslateService) {

    this.alerts = this.addAlert$.asObservable();
  }

  public getAlerts() {
    return this.alerts;
  }

  public addAlert(alert: Alert) {
    this.addAlert$.next(alert);
  }

  public handleCustomErrorResponse(responseError: IErrorResponseError) {
    let errorMessage = '';
    switch (responseError.errorCode) {
      case ERROR_INVALID_UPB_FOR_BRAID_API: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank for Braid API`); break;
      case ERROR_INVALID_SEGREGATED_PERSON_PROFILE: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Segregated Person Profile`); break;
      case ERROR_NOT_SAME_UPB_FOR_WIRE_ACC_AND_SEGREGATED_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Wire Account and Segregated Account doesn't have the same 'Upstream Bank'`); break;
      case ERROR_LEGAL_ENTITY_NOT_SYNCED_WITH_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: This Legal Entity is not synced with Braid`); break;
      case ERROR_INDIVIDUAL_NOT_SYNCED_WITH_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: This Individual is not synced with Braid`); break;
      case ERROR_INVALID_LOCAL_SEGREGATED_ACCOUNT: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Segregated Account`); break;
      case ERROR_INVALID_UPB_FOR_LOCAL_SEGREGATED_ACCOUNT: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank for Segregated Account`); break;
      case ERROR_INVALID_LOCAL_FUNDING_ACCOUNT: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Funding Account`); break;
      case ERROR_INVALID_UPB_FOR_LOCAL_FUNDING_ACCOUNT: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank for Funding Account`); break;
      case ERROR_UNKNOWN_KYC_PROFILE_TO_SYNC_WITH_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown KYC Profile to sync with Braid`); break;
      case ERROR_BUSINESS_BLOCKED_BY_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Failed to create account as this Business Record was blocked by Braid`); break;
      case ERROR_INDIVIDUAL_BLOCKED_BY_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Failed to create account as this Individual Record was blocked by Braid`); break;
      case ERROR_UNKNOWN_UPB: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown Upstream Bank`); break;
      case ERROR_BRAID_NOT_ENABLED_FOR_UPB: errorMessage = this.translateService.instant(`alerts.response-errors: Braid is not enabled on this Upstream Bank.`); break;
      case ERROR_UNKNOWN_PERSON: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown Person`); break;
      case ERROR_UNKNOWN_KYC_PROFILE: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown KYC Profile`); break;
      case ERROR_PERSON_TYPE_NOT_LEGAL_ENTITY: errorMessage = this.translateService.instant(`alerts.response-errors: This Person is not of type: 'LEGAL_ENTITY'`); break;
      case ERROR_PERSON_TYPE_NOT_INDIVIDUAL: errorMessage = this.translateService.instant(`alerts.response-errors: This Person is not of type: 'INDIVIDUAL'`); break;
      case ERROR_INVALID_ID_TYPE: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid ID Type`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_ORGANIZATION_TYPE: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Organization Type`); break;
      case ERROR_CREATING_BUSINESS_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Error while creating Business Record in Braid`); break;
      case ERROR_INVALID_BUSINESS_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Business Record in Braid`); break;
      case ERROR_UPDATING_BUSINESS_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Error while updating Business Record in Braid`); break;
      case ERROR_CREATING_INDIVIDUAL_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Error while creating Individual Record in Braid`); break;
      case ERROR_INVALID_INDIVIDUAL_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Individual Record in Braid`); break;
      case ERROR_UPDATING_INDIVIDUAL_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Error while updating Individual Record in Braid`); break;
      case ERROR_UNKNOWN_LOCAL_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown Account`); break;
      case ERROR_UNKNOWN_BRAID_ACC_NUMBER: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown Braid Account Number`); break;
      case ERROR_UNKNOWN_LOCAL_SEGREGATED_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Unknown Segregated Account`); break;
      case ERROR_ID_DOC_NOT_ATTACHED_IN_QUESTIONNAIRE: errorMessage = this.translateService.instant(`alerts.response-errors: ID Document is not attached in the questionnaire`); break;
      case ERROR_DOCS_NOT_ATTACHED_IN_QUESTIONNAIRE: errorMessage = this.translateService.instant(`alerts.response-errors: Documents are not attached in the questionnaire`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_LOCAL_SENDER_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Sender Account`); break;
      case ERROR_INVALID_UPB_FOR_LOCAL_SENDER_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank for Sender Account`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_LOCAL_RECEIVER_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Receiver Account`); break;
      case ERROR_INVALID_UPB_FOR_LOCAL_RECEIVER_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank for Receiver Account`); break;
      case ERROR_NOT_ALLOWED_LOCAL_ACC_SYNC_WITH_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Not allowed to synchronize IONFI Accounts with Braid that already have transactions`); break;
      case ERROR_LOCAL_SEGREGATED_ACC_MUST_HAVE_0_CURRENT_BALANCE: errorMessage = this.translateService.instant(`alerts.response-errors: Segregated Accounts must have 0 Current Balance`); break;
      case ERROR_INVALID_WFORM: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Wire Transfer Form`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_ORIGINATOR_PERSON: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Originator Person`); break;
      case ERROR_INVALID_BENEFICIARY_PERSON: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Beneficiary Person`); break;
      case ERROR_BRAID_ENABLED_BUT_WFORM_NOT_SEGREGATED: errorMessage = this.translateService.instant(`alerts.response-errors: Braid is enabled for this person but this Wire Transfer Form is not segregated`); break;
      case ERROR_LOCAL_SEGREGATED_ACC_NOT_SYNCED_WITH_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: This segregated account was not registered in Braid`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_BENEFICIARY_BANK: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Beneficiary Bank`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_INTERMEDIARY_BANK: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Intermediary Bank`); break;
      case ERROR_CANT_CREATE_TRAN_IN_BRAID_WITH_BANKS_WITH_SAME_NUM_TYPE: errorMessage = this.translateService.instant(`alerts.response-errors: Can't create transaction in Braid when Beneficiary and Intermediary Bank have the same Bank Number Type`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_WIRE_LOCAL_ACC: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Wire Account`); break;
      case ERROR_LOCAL_WIRE_ACC_NOT_REGISTERED_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: This Wire Account was not registered in Braid`); break;
      case ERROR_INVALID_DOMESTIC_AMOUNT_VALUE: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid 'domestic_amount' value`); break;
      case ERROR_CREATING_UPDATING_COUNTERPARTY_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Failed to create/update Counterparty in Braid`); break;
      case ERROR_UNSUPPORTED_WAY_TO_CREATE_TRAN_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Unsupported way to create transaction in Braid`); break;
      case ERROR_LOCAL_SEGREGATED_ACC_NOT_REGISTERED_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: This segregated account is not registered in Braid`); break;
      case ERROR_WFORM_INTERNATIONAL_INTER_BANK_FOR_NON_ABA_ROUTING_NUM_BENEFICIARY: errorMessage = this.translateService.instant(`alerts.response-errors: Intermediary Bank is Required when Beneficiary Bank does not have 'ABA Routing Number'`); break;
      case ERROR_INVALID_BENEFICIARY_PERSON_DDA_NUMBER: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Beneficiary Person DDA Number`); break;
      case ERROR_COUNTERPARTY_IN_BRAID_IS_NOT_ACTIVE: errorMessage = this.translateService.instant(`alerts.response-errors: Counterparty in Braid is not with status 'ACTIVE'`); break;
      case ERROR_INVALID_COUNTERPARTY_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Counterparty in Braid`); break;
      case ERROR_INVALID_TENANT_ID_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Tenant ID in Braid`); break;
      case ERROR_INVALID_USERNAME_OR_API_KEY_IN_BRAID: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Username/API Key.`); break;
      case ERROR_WHILE_VERIFYING_UPB_CREDENTIALS: errorMessage = this.translateService.instant(`alerts.response-errors: Error while verifying Upstream Bank credentials`); break;
      case ERROR_INVALID_BRAID_PRODUCT_DATA: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Braid Product Data`); break;
      // tslint:disable-next-line:max-line-length
      case ERROR_INVALID_BRAID_PRODUCT: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Product in Braid`); break;
      case ERROR_INVALID_UPB_CONFIG: errorMessage = this.translateService.instant(`alerts.response-errors: Invalid Upstream Bank Configuration`); break;
      case ERROR_INVALID_BRAID_WEBHOOKS_RESPONSE: errorMessage = this.translateService.instant(`alerts.response-errors: Server returned invalid Webhooks`); break;
      case ERROR_BRAID_INSUFFICIENT_FUNDS_ON_SENDER_ACCOUNT: errorMessage = this.translateService.instant(`alerts.response-errors: Insufficient funds on the Sender Account`); break;
      case ERROR_FEDWIRE_FILE_5000_OR_5010_REQUIRED: errorMessage = this.translateService.instant(`alerts.response-errors: Either {5000} or {5010} is required when {3600} is 'CTP' or 'CTR'`); break;
      case ERROR_WFORM_INTERNATIONAL_NO_BANK_WITH_ABA_ROUTING_NUMBER: errorMessage = this.translateService.instant(`alerts.response-errors: At least one Bank must be with 'ABA Routing Number' Bank Number type`); break;
      default: break;
    }
    if (errorMessage.length > 0) {
      this.addAlert$.next({type: 'danger', message: errorMessage });
    }
  }
}

export interface Alert {

  type: string;
  message: string;

}
