import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs";
import {Socket} from 'ngx-socket-io';
import {map} from 'rxjs/operators';

@Injectable()
export class TokenState {

  private reload$ = new BehaviorSubject<boolean>(true);

  constructor(private socket: Socket) {
  }

  reload() {
    this.reload$.next(true);
    this.socket.emit('authenticate', {token: this.getAccessToken()});
  }

  public getToken(): Observable<string | null> {
    return this.reload$.asObservable().pipe(
      map(it => this.getAccessToken())
    );
  }

  private getAccessToken(): string | null {
    return localStorage.session ? JSON.parse(localStorage.session).accessToken : null;
  }
}
