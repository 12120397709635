import {Injectable} from "@angular/core";
import {ApiService} from "@app/core-module/services/api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {IdType} from "@shared/models/id-type";
import {Observable} from "rxjs";
import {of} from "rxjs";

@Injectable()
export class IdTypesState {

  private idTypes: Observable<IdType[]>;

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService) {

    this.idTypes = this.rxUtilsService.createGetStateValue(of(true), this.apiService.getIdTypes(),
      'Error while reading ID Types', []);
  }

  public getIdTypes() {
    return this.idTypes;
  }
}
