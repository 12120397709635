<div class="login-page" [@routerTransition]>
  <div class="row justify-content-center h-100 m-0">

    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img src="./assets/images/stats-graphs-ion%200.svg" width="150px" class="user-avatar"/>
        <div class="mt-4 text-theme header-font">{{ 'root.forgot-password: Activate Your Password' | translate }}</div>
      </div>
    </div>

    <div class="col-6 p-0">
      <div class="row justify-content-end bg-theme m-0">
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a
          class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
        </div>
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a
          class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
        </div>
      </div>


      <div class="full-height bg-theme">
        <div class="col-12">
          <form *ngIf="showActivationField" role="form" [formGroup]="formGroup">
            <div class="form-content">
              <div class="form-group justify-content-center">
                <div class="col-12 inline-block">
                  <div class="text-left text-theme">{{'root.activate-account: Activation Code' | translate}}</div>
                  <input type="text" class="form-control rounded input-lg"
                         formControlName="code" (keypress)="hideErrorMessage()">
                  <div class="text-danger" *ngIf="showErrorMessage">{{errorMessage}}</div>
                </div>
              </div>
            </div>
            <button class="btn rounded-btn" [disabled]="!formGroup.valid"
                    (click)="activate()">{{'root.activate-account: Activate' | translate}}</button>
          </form>
          <div class="mt-2">
            <a class="link text-14" [routerLink]="'/auth/login'">{{ 'root.forgot-password: Go Back' | translate }}</a>
          </div>

          <div class="card mt-5" *ngIf="showActivating">
            <div class="card-body">
              {{'root.activate-account: Activating your account. Please wait.' | translate}}
            </div>
          </div>

          <div class="card mt-5" *ngIf="showActive">
            <div class="card-body" *ngIf="currentProfile | async; else not_logged">
              {{'root.activate-account: Your account is active. Go to your' | translate}} <a
              [routerLink]="'/profile'">{{'root.activate-account: profile page' | translate}}</a>.
            </div>
            <ng-template #not_logged>
              <div class="card-body" #not_logged>
                {{'root.activate-account: Your account is active. Go to the' | translate}} <a
                [routerLink]="'/auth/login'">{{'root.activate-account: login page' | translate}}</a>.
              </div>
            </ng-template>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
