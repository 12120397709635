<div class="login-page" [@routerTransition]>
  <div class="row justify-content-center h-100 m-0">

    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img src="./assets/images/stats-graphs-ion%200.svg" width="150px" class="user-avatar" />
        <div class="mt-4 text-theme header-font">{{ 'root.forgot-password: Reset Your Password' | translate }}</div>
      </div>
    </div>

    <div class="col-6 p-0">
      <div class="row justify-content-end bg-theme m-0">
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
        </div>
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
        </div>
      </div>
      <div class="full-height bg-theme">
      <div class="col-12">
      <form role="form" [formGroup]="formGroup" *ngIf="!forgotPasswordSent">
        <div class="form-content">
          <div class="form-group justify-content-center">
            <div class="col-6 inline-block p-0">
              <div class="text-left text-theme">{{ 'root.forgot-password: Email' | translate }}</div>
              <input #email type="text" class="form-control rounded input-lg" formControlName="email">
            </div>
          </div>
        </div>
        <button class="btn rounded-btn ld-ext-right col-6" [disabled]="!formGroup.valid" (click)="resetPassword()"
                [ngClass]="{'running': (working | async)}">
          {{ 'root.forgot-password: Reset Password' | translate }}
          <div class="ld ld-ring ld-spin"></div>
        </button>
      </form>
        <div class="mt-2">
          <a class="link text-14" [routerLink]="'/auth/login'">{{ 'root.forgot-password: Go Back' | translate }}</a>
        </div>
        <div class="card mt-5" *ngIf="forgotPasswordSent">
        <div class="card-body text-dark">
          <div>{{ 'root.forgot-password: Check your email, a reset link has been sent. Go to the' | translate }} <a [routerLink]="'/auth/login'">{{ 'root.forgot-password: login page' | translate }}</a>.
          </div>
          <div>{{ 'root.forgot-password: You didn\'t get an email?' | translate }}</div>
          <div>
            <button class="btn btn-sm btn-light ld-ext-right" [disabled]="!formGroup.valid" (click)="resetPassword()"
                    [ngClass]="{'running': (working | async)}">
              {{ 'root.forgot-password: Resend' | translate }}
              <div class="ld ld-ring ld-spin"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
        </div>
    </div>
  </div>
</div>
