const currencies = [
  {
    "value": "AED",
    "currencyName": "United Arab Emirates dirham"
  },
  {
    "value": "AFN",
    "currencyName": "Afghan afghani"
  },
  {
    "value": "ALL",
    "currencyName": "Albanian lek"
  },
  {
    "value": "AMD",
    "currencyName": "Armenian dram"
  },
  {
    "value": "ANG",
    "currencyName": "Netherlands Antillean guilder"
  },
  {
    "value": "AOA",
    "currencyName": "Angolan kwanza"
  },
  {
    "value": "ARS",
    "currencyName": "Argentine peso"
  },
  {
    "value": "AUD",
    "currencyName": "Australian dollar"
  },
  {
    "value": "AWG",
    "currencyName": "Aruban florin"
  },
  {
    "value": "AZN",
    "currencyName": "Azerbaijani manat"
  },
  {
    "value": "BAM",
    "currencyName": "Bosnia and Herzegovina convertible mark"
  },
  {
    "value": "BBD",
    "currencyName": "Barbados dollar"
  },
  {
    "value": "BDT",
    "currencyName": "Bangladeshi taka"
  },
  {
    "value": "BGN",
    "currencyName": "Bulgarian lev"
  },
  {
    "value": "BHD",
    "currencyName": "Bahraini dinar"
  },
  {
    "value": "BIF",
    "currencyName": "Burundian franc"
  },
  {
    "value": "BMD",
    "currencyName": "Bermudian dollar"
  },
  {
    "value": "BND",
    "currencyName": "Brunei dollar"
  },
  {
    "value": "BOB",
    "currencyName": "Boliviano"
  },
  {
    "value": "BOV",
    "currencyName": "Bolivian Mvdol"
  },
  {
    "value": "BRL",
    "currencyName": "Brazilian real"
  },
  {
    "value": "BSD",
    "currencyName": "Bahamian dollar"
  },
  {
    "value": "BTN",
    "currencyName": "Bhutanese ngultrum"
  },
  {
    "value": "BWP",
    "currencyName": "Botswana pula"
  },
  {
    "value": "BYN",
    "currencyName": "Belarusian ruble"
  },
  {
    "value": "BZD",
    "currencyName": "Belize dollar"
  },
  {
    "value": "CAD",
    "currencyName": "Canadian dollar"
  },
  {
    "value": "CDF",
    "currencyName": "Congolese franc"
  },
  {
    "value": "CHE",
    "currencyName": "WIR Euro"
  },
  {
    "value": "CHF",
    "currencyName": "Swiss franc"
  },
  {
    "value": "CHW",
    "currencyName": "WIR Franc"
  },
  {
    "value": "CLF",
    "currencyName": "Unidad de Fomento"
  },
  {
    "value": "CLP",
    "currencyName": "Chilean peso"
  },
  {
    "value": "CNY",
    "currencyName": "Renminbi  yuan"
  },
  {
    "value": "COP",
    "currencyName": "Colombian peso"
  },
  {
    "value": "COU",
    "currencyName": "Unidad de Valor Real"
  },
  {
    "value": "CRC",
    "currencyName": "Costa Rican colon"
  },
  {
    "value": "CUC",
    "currencyName": "Cuban convertible peso"
  },
  {
    "value": "CUP",
    "currencyName": "Cuban peso"
  },
  {
    "value": "CVE",
    "currencyName": "Cape Verdean escudo"
  },
  {
    "value": "CZK",
    "currencyName": "Czech koruna"
  },
  {
    "value": "DJF",
    "currencyName": "Djiboutian franc"
  },
  {
    "value": "DKK",
    "currencyName": "Danish krone"
  },
  {
    "value": "DOP",
    "currencyName": "Dominican peso"
  },
  {
    "value": "DZD",
    "currencyName": "Algerian dinar"
  },
  {
    "value": "EGP",
    "currencyName": "Egyptian pound"
  },
  {
    "value": "ERN",
    "currencyName": "Eritrean nakfa"
  },
  {
    "value": "ETB",
    "currencyName": "Ethiopian birr"
  },
  {
    "value": "EUR",
    "currencyName": "Euro"
  },
  {
    "value": "FJD",
    "currencyName": "Fiji dollar"
  },
  {
    "value": "FKP",
    "currencyName": "Falkland Islands pound"
  },
  {
    "value": "GBP",
    "currencyName": "Pound sterling"
  },
  {
    "value": "GEL",
    "currencyName": "Georgian lari"
  },
  {
    "value": "GHS",
    "currencyName": "Ghanaian cedi"
  },
  {
    "value": "GIP",
    "currencyName": "Gibraltar pound"
  },
  {
    "value": "GMD",
    "currencyName": "Gambian dalasi"
  },
  {
    "value": "GNF",
    "currencyName": "Guinean franc"
  },
  {
    "value": "GTQ",
    "currencyName": "Guatemalan quetzal"
  },
  {
    "value": "GYD",
    "currencyName": "Guyanese dollar"
  },
  {
    "value": "HKD",
    "currencyName": "Hong Kong dollar"
  },
  {
    "value": "HNL",
    "currencyName": "Honduran lempira"
  },
  {
    "value": "HRK",
    "currencyName": "Croatian kuna"
  },
  {
    "value": "HTG",
    "currencyName": "Haitian gourde"
  },
  {
    "value": "HUF",
    "currencyName": "Hungarian forint"
  },
  {
    "value": "IDR",
    "currencyName": "Indonesian rupiah"
  },
  {
    "value": "ILS",
    "currencyName": "Israeli new shekel"
  },
  {
    "value": "INR",
    "currencyName": "Indian rupee"
  },
  {
    "value": "IQD",
    "currencyName": "Iraqi dinar"
  },
  {
    "value": "IRR",
    "currencyName": "Iranian rial"
  },
  {
    "value": "ISK",
    "currencyName": "Icelandic króna"
  },
  {
    "value": "JMD",
    "currencyName": "Jamaican dollar"
  },
  {
    "value": "JOD",
    "currencyName": "Jordanian dinar"
  },
  {
    "value": "JPY",
    "currencyName": "Japanese yen"
  },
  {
    "value": "KES",
    "currencyName": "Kenyan shilling"
  },
  {
    "value": "KGS",
    "currencyName": "Kyrgyzstani som"
  },
  {
    "value": "KHR",
    "currencyName": "Cambodian riel"
  },
  {
    "value": "KMF",
    "currencyName": "Comoro franc"
  },
  {
    "value": "KPW",
    "currencyName": "North Korean won"
  },
  {
    "value": "KRW",
    "currencyName": "South Korean won"
  },
  {
    "value": "KWD",
    "currencyName": "Kuwaiti dinar"
  },
  {
    "value": "KYD",
    "currencyName": "Cayman Islands dollar"
  },
  {
    "value": "KZT",
    "currencyName": "Kazakhstani tenge"
  },
  {
    "value": "LAK",
    "currencyName": "Lao kip"
  },
  {
    "value": "LBP",
    "currencyName": "Lebanese pound"
  },
  {
    "value": "LKR",
    "currencyName": "Sri Lankan rupee"
  },
  {
    "value": "LRD",
    "currencyName": "Liberian dollar"
  },
  {
    "value": "LSL",
    "currencyName": "Lesotho loti"
  },
  {
    "value": "LYD",
    "currencyName": "Libyan dinar"
  },
  {
    "value": "MAD",
    "currencyName": "Moroccan dirham"
  },
  {
    "value": "MDL",
    "currencyName": "Moldovan leu"
  },
  {
    "value": "MGA",
    "currencyName": "Malagasy ariary"
  },
  {
    "value": "MKD",
    "currencyName": "Macedonian denar"
  },
  {
    "value": "MMK",
    "currencyName": "Myanmar kyat"
  },
  {
    "value": "MNT",
    "currencyName": "Mongolian tögrög"
  },
  {
    "value": "MOP",
    "currencyName": "Macanese pataca"
  },
  {
    "value": "MRU",
    "currencyName": "Mauritanian ouguiya"
  },
  {
    "value": "MUR",
    "currencyName": "Mauritian rupee"
  },
  {
    "value": "MVR",
    "currencyName": "Maldivian rufiyaa"
  },
  {
    "value": "MWK",
    "currencyName": "Malawian kwacha"
  },
  {
    "value": "MXN",
    "currencyName": "Mexican peso"
  },
  {
    "value": "MXV",
    "currencyName": "Mexican Unidad de Inversion"
  },
  {
    "value": "MYR",
    "currencyName": "Malaysian ringgit"
  },
  {
    "value": "MZN",
    "currencyName": "Mozambican metical"
  },
  {
    "value": "NAD",
    "currencyName": "Namibian dollar"
  },
  {
    "value": "NGN",
    "currencyName": "Nigerian naira"
  },
  {
    "value": "NIO",
    "currencyName": "Nicaraguan córdoba"
  },
  {
    "value": "NOK",
    "currencyName": "Norwegian krone"
  },
  {
    "value": "NPR",
    "currencyName": "Nepalese rupee"
  },
  {
    "value": "NZD",
    "currencyName": "New Zealand dollar"
  },
  {
    "value": "OMR",
    "currencyName": "Omani rial"
  },
  {
    "value": "PAB",
    "currencyName": "Panamanian balboa"
  },
  {
    "value": "PEN",
    "currencyName": "Peruvian sol"
  },
  {
    "value": "PGK",
    "currencyName": "Papua New Guinean kina"
  },
  {
    "value": "PHP",
    "currencyName": "Philippine peso"
  },
  {
    "value": "PKR",
    "currencyName": "Pakistani rupee"
  },
  {
    "value": "PLN",
    "currencyName": "Polish złoty"
  },
  {
    "value": "PYG",
    "currencyName": "Paraguayan guaraní"
  },
  {
    "value": "QAR",
    "currencyName": "Qatari riyal"
  },
  {
    "value": "RON",
    "currencyName": "Romanian leu"
  },
  {
    "value": "RSD",
    "currencyName": "Serbian dinar"
  },
  {
    "value": "RUB",
    "currencyName": "Russian ruble"
  },
  {
    "value": "RWF",
    "currencyName": "Rwandan franc"
  },
  {
    "value": "SAR",
    "currencyName": "Saudi riyal"
  },
  {
    "value": "SBD",
    "currencyName": "Solomon Islands dollar"
  },
  {
    "value": "SCR",
    "currencyName": "Seychelles rupee"
  },
  {
    "value": "SDG",
    "currencyName": "Sudanese pound"
  },
  {
    "value": "SEK",
    "currencyName": "Swedish krona/kronor"
  },
  {
    "value": "SGD",
    "currencyName": "Singapore dollar"
  },
  {
    "value": "SHP",
    "currencyName": "Saint Helena pound"
  },
  {
    "value": "SLL",
    "currencyName": "Sierra Leonean leone"
  },
  {
    "value": "SOS",
    "currencyName": "Somali shilling"
  },
  {
    "value": "SRD",
    "currencyName": "Surinamese dollar"
  },
  {
    "value": "SSP",
    "currencyName": "South Sudanese pound"
  },
  {
    "value": "STN",
    "currencyName": "São Tomé and Príncipe dobra"
  },
  {
    "value": "SVC",
    "currencyName": "Salvadoran colón"
  },
  {
    "value": "SYP",
    "currencyName": "Syrian pound"
  },
  {
    "value": "SZL",
    "currencyName": "Swazi lilangeni"
  },
  {
    "value": "THB",
    "currencyName": "Thai baht"
  },
  {
    "value": "TJS",
    "currencyName": "Tajikistani somoni"
  },
  {
    "value": "TMT",
    "currencyName": "Turkmenistan manat"
  },
  {
    "value": "TND",
    "currencyName": "Tunisian dinar"
  },
  {
    "value": "TOP",
    "currencyName": "Tongan paʻanga"
  },
  {
    "value": "TRY",
    "currencyName": "Turkish lira"
  },
  {
    "value": "TTD",
    "currencyName": "Trinidad and Tobago dollar"
  },
  {
    "value": "TWD",
    "currencyName": "New Taiwan dollar"
  },
  {
    "value": "TZS",
    "currencyName": "Tanzanian shilling"
  },
  {
    "value": "UAH",
    "currencyName": "Ukrainian hryvnia"
  },
  {
    "value": "UGX",
    "currencyName": "Ugandan shilling"
  },
  {
    "value": "USD",
    "currencyName": "United States dollar"
  },
  {
    "value": "USN",
    "currencyName": "United States dollar"
  },
  {
    "value": "UYI",
    "currencyName": "Uruguay Peso en Unidades Indexadas"
  },
  {
    "value": "UYU",
    "currencyName": "Uruguayan peso"
  },
  {
    "value": "UYW",
    "currencyName": "Unidad previsional"
  },
  {
    "value": "UZS",
    "currencyName": "Uzbekistan som"
  },
  {
    "value": "VES",
    "currencyName": "Venezuelan bolívar soberano"
  },
  {
    "value": "VND",
    "currencyName": "Vietnamese đồng"
  },
  {
    "value": "VUV",
    "currencyName": "Vanuatu vatu"
  },
  {
    "value": "WST",
    "currencyName": "Samoan tala"
  },
  {
    "value": "XAF",
    "currencyName": "CFA franc BEAC"
  },
  {
    "value": "XCD",
    "currencyName": "East Caribbean dollar"
  },
  {
    "value": "XDR",
    "currencyName": "Special drawing rights"
  },
  {
    "value": "XOF",
    "currencyName": "CFA franc BCEAO"
  },
  {
    "value": "XPF",
    "currencyName": "CFP franc"
  },
  {
    "value": "XSU",
    "currencyName": "SUCRE"
  },
  {
    "value": "XUA",
    "currencyName": "ADB Unit of Account"
  },
  {
    "value": "YER",
    "currencyName": "Yemeni rial"
  },
  {
    "value": "ZAR",
    "currencyName": "South African rand"
  },
  {
    "value": "ZMW",
    "currencyName": "Zambian kwacha"
  },
  {
    "value": "ZWL",
    "currencyName": "Zimbabwean dollar"
  }
];
export default currencies;
