<div class="tfa-page">
  <div class="row justify-content-center h-100 m-0">

    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img src="./assets/images/stats-graphs-ion%200.svg" width="150px" class="user-avatar" />
        <div class="mt-4 text-theme header-font">{{'root.tfa-setup: Two-Factor Authentication Setup' | translate}}</div>
      </div>
    </div>

    <div class="col-6 p-0">

      <div class="row justify-content-end bg-theme m-0">
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
        </div>
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
        </div>
      </div>

      <div class="full-height bg-theme tfa-setup">
        <div class="col-12" *ngIf="!successfullyInit">
        <div *ngIf="tfaSetupData != undefined">
          <form [formGroup]="tfaFormGroup" role="form">
              <div class="form-group text-theme">
                <hr>
                <h6 class="text-theme">{{'root.tfa-setup: Step 1' | translate}}</h6>
                <p>{{'root.tfa-setup: Download and install the' | translate}} <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                    target="blank">{{'root.tfa-setup: Google Authenticator' | translate}}</a> {{'root.tfa-setup: or' | translate}} <a href="https://authy.com/download/"
                    target="blank">{{'root.tfa-setup: Authy' | translate}}</a>
                  {{'root.tfa-setup: app on your device.' | translate}}</p>
                <hr>
                <h6 class="text-theme">{{'root.tfa-setup: Step 2' | translate}}</h6>
                <p>{{'root.tfa-setup: Open the authentication app and scan the QR code or enter the secret key.' | translate}}</p>
                <div class="qr-code">
                  <img class="qr-code-img" src="{{tfaSetupData.dataURL}}">
                </div>
                <br>
                <div class="form-group">
                  <div class="secret-key">
                    <span id="secret-key-label">{{'root.tfa-setup: Secret Key:' | translate}}</span> <span>{{tfaSetupData.tempSecret}}</span>
                  </div>
                </div>
                <hr>
                <h6 class="text-theme">{{'root.tfa-setup: Step 3' | translate}}</h6>
                <p>{{'root.tfa-setup: Enter the 6-digit verification code generated by the app.' | translate}}</p>
                <!-- <label>Verification Code</label> -->
                <input type="text" class="form-control" formControlName="tfaCode" placeholder="{{'root.tfa-setup: Verification code' | translate}}">
              </div>
            <div class="modal-footer d-flex justify-content-center">
              <div>
                <button class="btn btn-danger" type="button" (click)="logout()">{{'root.tfa-setup: Logout' | translate}}</button>
              </div>
              <div>
                <button type="submit" class="btn btn-dark" (click)="tfaSetupVerify()">{{'root.tfa-setup: Enable' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="successfullyInit" class="continue-div">
        <div *ngIf="currentProfile | async as profile">
          <button class="btn text-theme rounded-btn ld-ext-right" *ngIf="profile.user && profile.user.isAdminFI; else notAdminFi" [routerLink]="'/admin'">
            {{'root.tfa-setup: Click here to continue' | translate}}
          </button>
          <ng-template #notAdminFi>
            <button class="btn text-white rounded-btn ld-ext-right" [routerLink]="'/fi'">{{'root.tfa-setup: Click here to continue' | translate}}</button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
