import {Injectable} from '@angular/core';
import {shortenText} from '@app/shared-module/utils/utils';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import moment from 'moment';

@Injectable()
export class DebugService {


  private addDebugMessage$: Subject<DebugMessage> = new Subject<DebugMessage>();
  private debugMessages: Observable<DebugMessage>;

  constructor() {

    this.debugMessages = this.addDebugMessage$.asObservable();
  }

  public getDebugMessages() {
    return this.debugMessages;
  }

  public addNext(name: string, value: any) {
    let message = value ? shortenText(JSON.stringify(value), 50): value;
    this.addDebugMessage$.next({type: 'info', message: name + this.timestamp() + message});
  }

  public addComplete(name: string) {
    this.addDebugMessage$.next({type: 'success', message: name + ' completed!' + this.timestamp()});
  }

  public addError(name: string, error: any) {
    this.addDebugMessage$.next({type: 'danger', message: name + ' error: ' + this.timestamp() + JSON.stringify(error)});
  }

  private timestamp() {
    return ' ' + moment(new Date()).format('HH:mm:ss.SSS') + ' ';
  }

}

export interface DebugMessage {

  type: string;
  message: string;

}
