import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'errorMessage'
})
export class ErrorMessagePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(value: any, args?: any): any {
    value = value.split('-')[0].trim();
    switch (value) {
      case 'INVALID_ID_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Invalid ID Type!');
      case 'INVALID_ORGANISATION_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Invalid Organisation Type!');
      case 'INVALID_ENTITY_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Entity Type! (It can be only LEGAL_ENTITY or INDIVIDUAL)');
      case 'INVALID_PERSON_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Person Type! (It can be only RDC_ONLY, FULL_PROFILE, OR NON_CUSTOMER)');
      case 'MISSING_ID_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: ID Number');
      case 'MISSING_ID_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: ID Type');
      case 'MISSING_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Name');
      case 'MISSING_ORGANISATION_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Organisation Type');
      case 'MISSING_FIRST_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: First Name');
      case 'MISSING_LAST_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Last Name');
      case 'MISSING_DATE_OF_BIRTH':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Date of Birth');
      case 'MISSING_RESIDENCY':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Residency');
      case 'MISSING_CITIZENSHIP':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Citizenship');
      case 'MISSING_DATE_OF_INCORPORATION':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Date of Incorporation');
      case 'MISSING_COUNTRY_OF_INCORPORATION':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Country of Incorporation');
      case 'MISSING_ENTITY_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Entity Type');
      case 'DUPLICATE_MAIL':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Duplicate Email!');
      case 'DUPLICATE_ID':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Duplicate ID!');
      // Wire Transfer Forms error codes
      case 'MISSING_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Type!');
      case 'MISSING_AMOUNT':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Amount!');
      case 'MISSING_DESCRIPTION':
        return this.translateService.instant('shared.error-message-pipe: Missing Data: For further benefit description');
      case 'MISSING_BENEFICIARY_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Bank number type!');
      case 'MISSING_BENEFICIARY_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Bank number!');
      case 'MISSING_BENEFICIARY_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Bank name!');
      case 'MISSING_BENEFICIARY_PERSON_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Person number type!');
      case 'MISSING_BENEFICIARY_PERSON_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Person number!');
      case 'MISSING_BENEFICIARY_PERSON_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Beneficiary Person name!');
      case 'INVALID_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Type! (It can be only "DOMESTIC" or "INTERNATIONAL")');
      case 'MISSING_CURRENCY':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Currency!');
      case 'INVALID_CURRENCY':
        return this.translateService.instant('shared.error-message-pipe: Invalid data found: Currency!');
      case 'MISSING_RECEIVER_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Receiver Bank number type!');
      case 'MISSING_RECEIVER_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Receiver Bank number!');
      case 'MISSING_RECEIVER_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Receiver Bank name!');
      case 'MISSING_INTERMEDIARY_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Intermediary Bank number type!');
      case 'MISSING_INTERMEDIARY_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Intermediary Bank number!');
      case 'MISSING_INTERMEDIARY_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Intermediary Bank name!');
      case 'MISSING_ORIGINATOR_PERSON_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Originator Person number type!');
      case 'MISSING_ORIGINATOR_PERSON_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Originator Person number!');
      case 'MISSING_ORIGINATOR_PERSON_NAME':
        return this.translateService.instant('shared.error-message-pipe: Missing data: Originator Person name!');
      case 'INVALID_BENEFICIARY_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Bank number type!');
      case 'INVALID_BENEFICIARY_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Bank number!');
      case 'INVALID_BENEFICIARY_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Bank name!');
      case 'INVALID_BENEFICIARY_PERSON_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Person number type!');
      case 'INVALID_BENEFICIARY_PERSON_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Person number!');
      case 'INVALID_BENEFICIARY_PERSON_NAME':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Beneficiary Person name!');
      case 'INVALID_RECEIVER_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Receiver Bank number type!');
      case 'INVALID_RECEIVER_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Receiver Bank number!');
      case 'INVALID_RECEIVER_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Receiver Bank name!');
      case 'INVALID_INTERMEDIARY_BANK_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Intermediary Bank number type!');
      case 'INVALID_INTERMEDIARY_BANK_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Intermediary Bank number!');
      case 'INVALID_INTERMEDIARY_BANK_NAME':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Intermediary Bank name!');
      case 'INVALID_ORIGINATOR_PERSON_NUMBER_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Originator Person number type!');
      case 'INVALID_ORIGINATOR_PERSON_NUMBER':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Originator Person number!');
      case 'INVALID_ORIGINATOR_PERSON_NAME':
        return this.translateService.instant('shared.error-message-pipe: Invalid data: Originator Person name!');
        //Merge Profile
      case 'INVALID_CANNOT_MERGE_PROFILE_WITHOUT_ENTITY_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Cannot merge Profile that does not have entity type set!');
      case 'INVALID_DIFFERENT_ENTITY_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Merging profiles from different entity types is not allowed');
      case 'INVALID_DIFFERENT_FI':
        return this.translateService.instant('shared.error-message-pipe: Merging profiles from different financial institutions is not allowed');
      case 'INVALID_MORE_THAN_ONE_MASTER_DEFINED_IN_DB':
        return this.translateService.instant('shared.error-message-pipe: invalid data in database, more then one Person in the same Profile is defined as master');
      case 'INVALID_PROFILE_DOES_NOT_HAVE_MASTER':
        return this.translateService.instant('shared.error-message-pipe: invalid state, this profile does not have any master');
      case 'INVALID_BANKS_MERGING_NOT_SUPPORTED':
        return this.translateService.instant('shared.error-message-pipe: Merging profiles with Banks is not supported');
      case 'INVALID_TWO_OR_MORE_MONGO_DOCUMENTS_FOR_PROFILE_ID':
        return this.translateService.instant('shared.error-message-pipe: Two Mongo documents for same profile_id');
      case 'INVALID_CANNOT_CHANGE_ENTITY_TYPE':
        return this.translateService.instant('shared.error-message-pipe: Changing Entity Types is not allowed');
      default:
        return this.translateService.instant('shared.error-message-pipe: Error occurred, please try again later!');
    }
  }

}
