import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {UsersType} from '@shared/models/users-type';
import {filter, merge} from 'rxjs/operators';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';

@Injectable()
export class UserTypesState {

  private userTypes: Observable<UsersType[]>;

  constructor(private currentBankState: CurrentBankState,
              private apiService: ApiService,
              private rxUtilsService: RxUtilsService) {

    const initiator = this.currentBankState.getCurrentBankId().pipe(
      filter(it => it != null)
    );
    this.userTypes = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getUserTypes(),
      'Error while reading User Types', []);
  }

  public getUserTypes() {
    return this.userTypes;
  }

}
