import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../services/api.service';
import {RxUtilsService} from '../services/rx-utils.service';
import {filter, merge} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {TranStatus} from '@shared/models/tran-status';

@Injectable()
export class TransactionStatusesState {

  private transactionStatusesObservable: Observable<TranStatus[]>;
  private readingObservable: Observable<boolean>;
  private readFilters = new Subject();

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService,
              private currentProfileState: CurrentProfileState) {

    const initiator = this.currentProfileState.getCurrentProfileId().pipe(
      filter(it => it != null),
      merge(this.readFilters)
    );
    this.transactionStatusesObservable = this.rxUtilsService.createGetStateValue(initiator, this.apiService.getTransactionStatuses(),
      'Error while getting activity state filters', null);
    this.readingObservable = this.rxUtilsService.createReadingInfo(initiator, this.transactionStatusesObservable);

  }

  getTransactionStatuses() {
    return this.transactionStatusesObservable;
  }

  getReading() {
    return this.readingObservable;
  }

}
